import React from "react";
import { motion } from "framer-motion";
const handleScroll = (e) => {
  e.preventDefault();
  const targetId = e.currentTarget.getAttribute("href").substring(1); // Remove o "#"
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth" });
  }
};
function Apresenta() {
  return (
   <motion.div
      initial={{ scale: 0.9, opacity: 0.8 }} // Começa pequeno e invisível
      animate={{ scale: 1, opacity: 1 }} // Aumenta para o tamanho normal
      transition={{ duration: 0.5,ease: "easeOut"  }} // Duração da animação
    >
    <section className="apresentacao">
        <div className="container-grid">
            <h1>Transformamos Ideias em Soluções Digitais</h1>
            <p>Somos especializados em desenvolvimento de apps Android e Desktop, bem como desenvolvimento web.</p>
            <a href="#contato" className="botao-orcamento"  onClick={handleScroll}>Solicitar Orçamento</a>
        </div>
    </section>
    </motion.div>
  );
}

export default Apresenta;