import React from "react";
import { motion } from "framer-motion";


function Apresenta() {
  return (
 
    <section className="apresenta-sub">
      <h1>Apresentação Impactante</h1>
      <div className="container-flex">
      <motion.div
        style={{margin:15}}
         whileInView={{ opacity: 1, y: 0 }}
         initial={{ opacity: 0, y: 150 }} // Começa invisível e menor
         viewport={{ once: true, amount: 0.2 }}
         transition={{ duration:0.5 }} // Duração da animação
    >
        <div className="container-grid apresenta">
          <h2>Transforme Sua Ideia em Realidade Digital</h2>
          <p>Soluções completas em desenvolvimento web e mobile para o seu negócio decolar. Especialistas em criar experiências digitais inovadoras, focados em resultados e na satisfação do cliente.</p>
        </div>
        </motion.div>
        <motion.div
          style={{margin:15}}
          whileInView={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 150 }} // Começa invisível e menor
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration:0.9 }} // Duração da animação
    >
        <div className="container-grid apresenta">
          <h2>Solicite um Orçamento Gratuito</h2>
          <p>Entre em contato conosco hoje mesmo e descubra como podemos ajudar a impulsionar o seu negócio com soluções digitais sob medida. Nossa equipe está pronta para transformar suas ideias em realidade!</p>
        </div>
        </motion.div>
      </div>
    </section>
  
  );
}

export default Apresenta;