import React from "react";
import { motion } from "framer-motion";

const servicos = [
  {
    titulo:"Desenvolvimento Web", 
    texto:"Criação de websites responsivos, dinâmicos e otimizados para SEO, utilizando as tecnologias mais modernas do mercado: PHP, JavaScript, CSS e React.",
    borda:"#24E283",
    animate:0.5
  },
  {
    titulo:"Frameworks e Bibliotecas", 
    texto:"Utilização de frameworks e bibliotecas robustas para agilizar o desenvolvimento, garantir a escalabilidade e a segurança das suas aplicações web: Laravel, Node.js.",
    borda:"#018CE1",
    animate:0.9
  },
  {
    titulo:"Desenvolvimento Mobile", 
    texto:"Criação de aplicativos nativos para Android, com foco na performance, na experiência do usuário e na integração com as funcionalidades do dispositivo: Java, Kotlin (Android).",
    borda:"#DA33BF",
    animate:1.3
  },
  {
    titulo:"Desenvolvimento Desktop", 
    texto:"Desenvolvimento de aplicativos de desktop modernos e dinâmicos em C#, usando a poderosa tecnologia WPF da Microsoft.",
    borda:"#204C8E",
    animate:1.7
  },
];

function Servicos() {
  return (
    <motion.div
    whileInView={{ opacity: 1, scale: 1 }}
    initial={{ opacity: 0, scale: 0.8 }} // Começa invisível e menor
    transition={{ duration: 0.5 }} // Tempo da animação
    viewport={{ once: true, amount: 0.3 }} // Reanima quando 30% do elemento aparecer
  >
   <section className="container-flex servicos" id="servicos">
    <div className="servicos-conteudo">
      <h1>
      Nossos Serviços Especializados
      </h1>
      <div className="lista-servicos">
      {servicos.map((servico, index) => (
           <motion.div
            whileInView={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 250 }} // Começa invisível e menor
            transition={{duration: (servico.animate) }} // Tempo da animação
            viewport={{ once: true, amount: 0.1 }} // Reanima quando 30% do elemento aparecer
            style={{ borderColor: servico.borda }}
            className="item-servicos"
            key={index}>
            <h2>{servico.titulo}</h2>
            <p>{servico.texto}</p>
   
          </motion.div>
        ))}
    </div>
    </div>

      <motion.div
          className="image-lateral"
          style={{position:'relative'}}
          whileInView={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 150 }} // Começa invisível e menor
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration:0.7 }} // Duração da animação
          >
      <div>
      
      </div>
    </motion.div>
   </section>
   </motion.div>
  );
}

export default Servicos;