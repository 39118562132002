import React, { useState } from "react";
import { motion } from "framer-motion";

function Contato() {
  const [form, setForm] = useState({ nome: "", assunto: "", mensagem: "" });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(form.nome===""||form.assunto===""||form.mensagem==="") {
      alert('Preencha todos os campos')
      return;
    }
    let msg = `*Nome*: ${form.nome}\n*Assunto*: ${form.assunto}\n*Mensagem*: ${form.mensagem}`;
    const url = `https://wa.me/5577998050013?text=${encodeURIComponent(msg)}`;
    window.open(url,'_blank');
  };

  return (
    <motion.div
    whileInView={{ opacity: 1, scale: 1 }}
    initial={{ scale: 0.9, opacity: 0.8 }} // Começa invisível e menor
    transition={{ duration: 0.5 }} // Tempo da animação
    viewport={{ once: true, amount: 0.3 }} // Reanima quando 30% do elemento aparecer
  >
    <footer className="contato" id="contato">
      <h1>Conecte-se Conosco e Dê o Próximo Passo</h1>
      <div className="container-flex contato-conteudo">
        <div className="contato-coluna">
          <h2>Pronto para Iniciar Seu Projeto?</h2>
          <p>
          Entre em contato e vamos conversar sobre como podemos ajudar você a alcançar seus objetivos. Preencha o formulário abaixo para solicitar um orçamento ou tirar suas dúvidas.
          </p>
        </div>
        <div className="contato-coluna">
        <motion.div
        whileInView={{ opacity: 1, y: 0 }} // Elemento na posição final (visível)
        initial={{ opacity: 0, y: 100 }} // Começa abaixo da tela e menor
        transition={{ duration: 0.8, ease: "easeOut" }} // Transição mais suave
        viewport={{ once: true, amount: 0.4 }} // Inicia quando 20% do elemento aparecer
        style={{
          bottom: "-100px", // Inicia abaixo da tela
          transform: "translateX(-50%)", // Centraliza
        }}
        >
      <form>
        <input
          type="text"
          name="nome"
          placeholder="Seu Nome"
          value={form.nome}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="assunto"
          placeholder="Assunto"
          value={form.assunto}
          onChange={handleChange}
          required
        />
        <textarea
          name="mensagem"
          placeholder="Sua mensagem"
          value={form.mensagem}
          onChange={handleChange}
          required
        />
        <button 
        onClick={handleSubmit}
        type="button"
        >Iniciar Atendimento</button>
      </form>
        </motion.div>
        </div>
      </div>
    </footer>
    </motion.div>
  );
}

export default Contato;