import React from "react";
const handleScroll = (e) => {
  e.preventDefault();
  const targetId = e.currentTarget.getAttribute("href").substring(1); // Remove o "#"
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    targetElement.scrollIntoView({ behavior: "smooth" });
  }
};

function Header() {
  return (
    <header data-node-view-wrapper="" style={{ whiteSpace: 'normal' }}>
  <div className="nav">
  <a className="logo" href="/">
        <img alt="Logo AppMania" src="logo.png"/>
    </a>
    <div className="espaco"></div>
  <div className="botoes-nav">
    <a href="#servicos" onClick={handleScroll}>Serviços</a>
    <a href="#contato" onClick={handleScroll}>Contato</a>
 </div>
  </div>
</header>
  );
}

export default Header;