import React from "react";
import Header from "./components/Header";
import Servicos from "./components/Servicos";
import Contato from "./components/Contato";
import Apresenta from "./components/Apresenta";
import ApresentaSub from "./components/ApresentaSub";

function App() {
  return (
    <div className="app-mania">
      <Header />
      <Apresenta />
      <ApresentaSub/>
     
        <Servicos />
 
      <Contato />
    </div>
  );
}

export default App;